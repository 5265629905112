import React, { useEffect, useMemo } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy } from 'react-table'

import CopyableLink from '../components/common/CopyableLink'
import Layout from '../components/layouts/Layout'
import MainBox from '../components/common/MainBox'
import { Option } from '../lib/helpers'
import { Organizations_organizations } from '../hasura/graphQlQueries/types/Organizations'
import { Table, Header, HeaderCell, NarrowCell, TextCell } from '../components/common/Table'
import { updateOrganizationPriceGroupAction } from '../hasura/slices/pricing'
import { usersSelector, UsersState } from '../hasura/slices/users'

import {
  OrganizationsState,
  fetchOrganizationsAction,
  fetchPricesGroupsAction,
  organizationsSelector,
} from '../hasura/slices/organizations'

const defaultColumn: any = {
  Cell: TextCell,
}

export default function Practices() {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)
  const { organizations, priceGroups }: OrganizationsState = useSelector(organizationsSelector)

  /*
    Effects
  */

  useEffect(() => {
    if (organizations.length || !user) return

    dispatch(fetchOrganizationsAction(accessToken, user.organization.enterprise.id))
  }, [organizations, user])

  useEffect(() => {
    if (!accessToken || !user) return

    dispatch(fetchPricesGroupsAction(accessToken, user.organization.enterprise.id))
  }, [accessToken, user])

  /*
    Methods
  */

  const updatePriceGroup = (organization_id: number, price_group_id: number) =>
    dispatch(updateOrganizationPriceGroupAction(accessToken, user!, organization_id, price_group_id))

  const priceGroupOptions: Option[] = (priceGroups || []).map((group) => ({
    label: group.display_name || `Price Group ${group.id}`,
    value: group.id,
  }))

  const columns = useMemo(
    () => [
      {
        Header: 'Created',
        accessor: (o: Organizations_organizations) => moment(o.created_at).fromNow(),
      },
      {
        Header: 'Name',
        accessor: 'display_name',
      },
      {
        Header: 'Email',
        accessor: 'primary_email',
      },
      {
        Header: 'Price Group',
        accessor: (o: Organizations_organizations) => o,
        Cell: (c: { value: Organizations_organizations }) => {
          return (
            <div>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                value={priceGroupOptions.find((o) => o.value === c.value.price_group_id)}
                onChange={(option: Option | null) => {
                  if (!option) return

                  updatePriceGroup(c.value.id, option.value)
                }}
                options={priceGroupOptions}
              />
            </div>
          )
        },
      },
    ],
    [accessToken, user, priceGroups]
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: organizations,
      defaultColumn,
    },
    useSortBy
  )

  return (
    <Layout>
      <MainBox defaultPadding>
        <div className="d-flex align-items-start justify-content-between">
          <h4 className="bold">Practices</h4>
        </div>

        <div className="max-width-900px">
          <div className="border rounded text-s p-3">
            <CopyableLink
              prompt="To invite practices to your clinic, send them your unique link:"
              link={`vet.radimal.ai/enterprise?i=${
                user?.organization.enterprise.id
              }&n=${user?.organization.enterprise.short_name.toLowerCase().replace(/ /g, '-')}`}
            />
          </div>

          <Table cellSpacing={0} {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any, idx: number) => (
                    <HeaderCell noBorder key={idx} {...column.getHeaderProps(column.getSortByToggleProps({}))}>
                      {column.Header && <Header>{column.render('Header')}</Header>}
                    </HeaderCell>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell: any, idx: number) => {
                      return (
                        <NarrowCell key={idx} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </NarrowCell>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      </MainBox>
    </Layout>
  )
}
